import React, { useState } from 'react';
import { Menu, Drawer, Button, Badge, message } from 'antd';
import { BarChartOutlined, ClockCircleOutlined, DollarCircleTwoTone, DownloadOutlined, HistoryOutlined, LogoutOutlined, ProjectOutlined, ToolOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  FileDoneOutlined,
  MessageOutlined,
  FileImageOutlined,
  SoundOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import Logo from '../assets/img/logo.png';

const { SubMenu } = Menu;

const rootRoutes = ['/', '/about', '/contact'];
const aboutSubRoutes = ['/about/me', '/about/company'];
import { Activity, BarChart2, Columns as ColumnIcon, Globe, HelpCircle, List, Mic } from 'react-feather';
import DocumentIcon from 'icons/DocumentIcon';
import TransformerIcon from 'icons/TransformerIcon';
import DubbingIcon from 'icons/DubbingIcon';
import ChatbotIcon from 'icons/ChatbotIcon';
import DocumentTranslationIcon from 'pages/translation/icons/DocumentTranslationIcon';
import './MainSidebar.less';
import api_config from 'lib/api/common';
import UseAPI from 'lib/api/UseAPI';
import UpgradeButton from 'pages/common/UpgradeButton';
import PlanComponentPopup from 'pages/plans/PlanComponentPopup';
import CountdownTimer from './CountDownTimer';
import { Bars } from 'react-loader-spinner';


const MainSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => setCollapsed(!collapsed);
  const [visible, setVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState(['voiceover']);
  const [showPlanPopup, setShowPlanPopup] = useState(false);

  const navigate = useNavigate();

  const handleLogOut = () => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/logout/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}

    UseAPI(signal, url, "GET", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
          localStorage.clear();
          navigate('/login');
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Request aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });
  };

  // Toggle sidebar visibility
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const handleMenuClick = (e) => {
    // if (mobileDrawerVisible) {
    //   handleDrawerClose();
    // }
    setOpenKeys([e.key]);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  return (
    <>
      {/* Button to open the sidebar */}
      {/* <Button type="primary" onClick={showDrawer} style={{ position: 'fixed', top: 20, left: 20 }}>
        Open Sidebar
      </Button> */}
      <div className='desktop-only' style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'start', alignItems: 'center', position: 'fixed', left: '0px', top: '5px', zIndex: '999'}}>
        <div className="logo" style={{alignItems: 'center'}}>
        <Button
            type="text"
            icon={collapsed ? <></> : <ColumnIcon />}
            onClick={showDrawer}
            className='main-menu-fold'
            style={{
                fontSize: '16px',
                width: 64,
                height: 64,
            }}
        />
        <Bars
              height="30"
              width="40"
              color="#2F2F2F"
              ariaLabel="bars-loading"
              wrapperStyle={{display: 'flex', justifyContent: 'center', marginRight: '4px'}}
              wrapperClass=""
              visible={true}
            />
        <img style={{ width: '120px' }} src={Logo} alt="Unmixr" /> 
        </div>
    </div>
      {/* Drawer component for the sidebar */}
      <Drawer
        title={<div>
          <img style={{ width: '120px' }} src={Logo} alt="Unmixr" />
          <div style={{marginTop: '20px'}}><UpgradeButton onClick={() => setShowPlanPopup(true)}/></div>
        </div>}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        mask={true} // Enable backdrop
        maskClosable={true} // Close when backdrop is clicked
        width={300} // Width of the sidebar
        bodyStyle={{ padding: '0px 20px' }} // Add custom padding if needed
      >
        <div style={{ flexGrow: '1', paddingBottom: '20px' }} className='ant-layout-sider'>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[
                aboutSubRoutes.includes(window.location.pathname)
                  ? '1'
                  : rootRoutes.indexOf(window.location.pathname).toString(),
              ]}
              selectedKeys={openKeys}
              onOpenChange={handleOpenChange}
              onClick={handleMenuClick}
            >
                
                <Menu.Item key="0">
                <Link to="/dashboard">
                  <BarChartOutlined />
                  <span className="menu-item-link">Dashboard</span>
                </Link>
              </Menu.Item>
              </Menu>
              <h3>Products</h3>
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[
                  aboutSubRoutes.includes(window.location.pathname)
                    ? '1'
                    : rootRoutes.indexOf(window.location.pathname).toString(),
                ]}
                selectedKeys={openKeys}
                onOpenChange={handleOpenChange}
                onClick={handleMenuClick}
              >
              <SubMenu
                key="voiceover"
                title={
                  <span>
                    <SoundOutlined />
                    <span className="menu-item-link" >TexttoSpeech</span>
                  </span>
                }
              >
                
                <Menu.Item key="voice_studio_desktop">
                  <Link to="/studio">
                    <Activity style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">Long Voice Studio(v2)</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="dialogue_studio_desktop">
                  <Link to="/dialogue-studio">
                    <Activity style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">Dialogue Studio(v1)</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="voice_cloning_desktop">
                  <Link to="/voice-cloning">
                    <Mic style={{ width: '18px', height: '18px', marginRight: '3px' }} />
                    <span className="menu-item-link">Voice Cloning</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ai_audio_merge_requests">
                  <Link to="/my-audios">
                    <List  style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">Audio Files</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ai_my_drafts" onClick={handleMenuClick}>
                  <Link to="/my-drafts">
                    <ClockCircleOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">My Drafts</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ai_voices">
                  <Link to="/voicelist">
                    <List  style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">AI Voices</span>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="ai-audio-scrit-ai_desktop" onClick={handleMenuClick}>
                  <Link to="/ai-audio-script">
                    <span className="menu-item-link"><ScriptIcon /> AI ScriptBot</span>
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="tools-create-subtitle-desktop" onClick={handleMenuClick}>
                  <Link to="/create-subtitle">
                    <DownloadOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">Create Subtitle</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="tools-audio-converter-desktop" onClick={handleMenuClick}>
                  <Link to="/tools/audio-converter/">
                    <ToolOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">Audio Converter</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ai-studio-widget-settings-desktop" onClick={handleMenuClick}>
                  <Link to="/studio/widget-settings/">
                    <span className="menu-item-link"><SettingOutlined />Widget Settings</span>
                  </Link>
                </Menu.Item>
                {
                  false && 
                  <Menu.Item key="ai_vo_user_guide_desktop">
                  <Link to="/voiceover/user-guide">
                    <HelpCircle style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                    <span className="menu-item-link">User Guide</span>
                  </Link>
                </Menu.Item>
                }
              </SubMenu>
              <Menu.Item key="5"  onClick={() => setCollapsed(!collapsed)}> 
                <Link to="/chat-with-ai">
                  <ChatbotIcon/>
                  <span style={{paddingLeft: '3px'}} className="menu-item-link">AI Chatbot</span>
                </Link>
              </Menu.Item>
              <SubMenu
                key="ai-transcriptor-menu"
                title={
                  <span>
                    <DubbingIcon size={'24px'}/>
                    <span className="menu-item-link">Transcription</span>
                  </span>
                }
              >
                  {/* <Menu.Item key="ai_transcriptor_desktop" onClick={() => setCollapsed(!collapsed)}> */}
                  <Menu.Item key="ai_transcriptor_desktop">
                    <Link to="/transcriptions">
                      <BarChart2 style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                      <span className="menu-item-link" >My Transcriptions</span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="ai_transcriptor_user_guide_desktop">
                    <Link to="/dubbing-studio/user-guide">
                      <HelpCircle style={{width: '18px', height: '18px', marginRight: '3px'}}/>
                      <span className="menu-item-link">User Guide</span>
                    </Link>
                  </Menu.Item> */}
              </SubMenu>
              {/* <Menu.Item key="6"  onClick={() => setCollapsed(!collapsed)}> */}
              <Menu.Item key="ai_document-translator-desktop">
                <Link to="/document-translation">
                  <span style={{marginRight: '3px'}}><DocumentTranslationIcon size='1rem' /></span> 
                  <span className="menu-item-link">Doc Translation</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/ai-image">
                  <FileImageOutlined />
                  <span className="menu-item-link">AI Image Studio {false && <Badge count={'updated'} style={{ backgroundColor: '#52c41a' }}></Badge>}</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="ai_transcriptor-temp-desktop">
                <Link to="/templates/translator">
                  <Globe style={{width: '21px', height: '21px', marginRight: '5px'}}/>
                  <span className="menu-item-link">Text Translator</span>
                </Link>
              </Menu.Item>
              <SubMenu
                key="ai-chat-copywriting-menu"
                title={
                  <span>
                    <MessageOutlined />
                    <span className="menu-item-link">Copywriting Tools</span>
                  </span>
                }
              >
                    <Menu.Item key="1">
                      <Link to="/templates">
                        <AppstoreOutlined />
                        <span className="menu-item-link">Templates</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="ai_transformer-desktop">
                      <Link to="/templates/transformer">
                        <TransformerIcon />
                        <span className="menu-item-link" style={{marginLeft: '15px'}}>Transform Text</span>
                      </Link>
                    </Menu.Item>
                    {/* <Menu.Item key="mn_new_note"  onClick={() => setCollapsed(!collapsed)}> */}
                    <Menu.Item key="mn_new_note">
                      <Link to="/documents/new">
                        <DocumentIcon />
                        <span className="menu-item-link"><span style={{marginLeft: '10px'}}>AI Writing Editor</span></span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="mn_my_notes">
                      <Link to="/documents">
                        <FileDoneOutlined />
                        <span className="menu-item-link">My Documents</span>
                      </Link>
                    </Menu.Item>
              </SubMenu>
            </Menu>
            <h3>Settings</h3>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[
                  aboutSubRoutes.includes(window.location.pathname)
                    ? '1'
                    : rootRoutes.indexOf(window.location.pathname).toString(),
                ]}
                selectedKeys={openKeys}
                onOpenChange={handleOpenChange}
                onClick={handleMenuClick}
              >
              <Menu.Item key="my_plan_key">
                <Link to="/plan">
                  <DollarCircleOutlined />
                  <span className="menu-item-link">Pricing and Plans</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="my_plan_key_buy_addons" onClick={handleMenuClick}>
                <Link to="/buy-credits">
                  <DollarCircleTwoTone />
                  <span className="menu-item-link">Buy Credits</span>
                </Link>
              </Menu.Item>
              {
                false && 
                <SubMenu
                  key="history"
                  title={
                    <span>
                      <HistoryOutlined />
                      <span className="menu-item-link">History</span>
                    </span>
                  }
                >
                  <Menu.Item key="history_chat">
                    <Link to="/chat-history">
                      <HistoryOutlined />
                      <span className="menu-item-link">Chat History</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="word_usage_history">
                    <Link to="/word-usage-history">
                      <HistoryOutlined />
                      <span className="menu-item-link">Word History</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              }
              <Menu.Item key="help_support_key">
                <Link to="/help">
                  <QuestionCircleOutlined />
                  <span className="menu-item-link">Help & Support</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="settings_key">
                <Link to="/settings">
                  <SettingOutlined />
                  <span className="menu-item-link">Profile Settings</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="features_roadmap_key_desktop">
              <Link to="/features-roadmap">
                <ProjectOutlined />
                <span className="menu-item-link">Product Roadmap</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="logout_key_desktop">
              <Link onClick={() => handleLogOut()}>
                <LogoutOutlined />
                <span className="menu-item-link">Logout</span>
              </Link>
            </Menu.Item>
            </Menu>
          </div>
      </Drawer>
      {showPlanPopup && (
        <PlanComponentPopup onCancelled={() => setShowPlanPopup(false)} />
      )}
      {
        false && <CountdownTimer targetDate="2024-12-07T23:59:59" onClick={() => {setShowPromoBanner(true);}} />
      }
    </>
  );
};

export default MainSidebar;
