import React, { useRef, useState, useEffect, useContext } from 'react';
import { Layout, Menu, Drawer, Button, message, Dropdown, Tag, notification, Tooltip, Badge, Alert } from 'antd';
import { BarChartOutlined, BranchesOutlined, CheckCircleOutlined, ClockCircleOutlined, DollarCircleTwoTone, DollarOutlined, DownCircleFilled, DownCircleOutlined, DownloadOutlined, DownOutlined, DownSquareFilled, DownSquareOutlined, DownSquareTwoTone, HistoryOutlined, LoadingOutlined, LogoutOutlined, PlusOutlined, ProjectOutlined, ToolOutlined, TranslationOutlined, UpCircleFilled, UpCircleOutlined, UpOutlined } from '@ant-design/icons';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import UseAPI from 'lib/api/UseAPI';
import api_config from 'lib/api/common';
import { StoreContext } from 'store';
const { Header } = Layout;
import {
  AppstoreOutlined,
  FileDoneOutlined,
  MessageOutlined,
  FileImageOutlined,
  MenuOutlined,
  SoundOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import './MainLayout.less';
import Logo from '../assets/img/logo.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

const rootRoutes = ['/', '/about', '/contact'];
const aboutSubRoutes = ['/about/me', '/about/company'];
import Navbar from './NavBar';
import PromoBanner from 'components/common/PromoBanner';
import { Activity, BarChart, BarChart2, Columns as ColumnIcon, DollarSign, Globe, HelpCircle, List, Mic, Settings, Volume2 } from 'react-feather';
import DocumentIcon from 'icons/DocumentIcon';
import TransformerIcon from 'icons/TransformerIcon';
import DubbingIcon from 'icons/DubbingIcon';
import ChatbotIcon from 'icons/ChatbotIcon';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import InitialAssistantComponent from 'components/common/InitialAssistantComponent';
import DocumentTranslationIcon from 'pages/translation/icons/DocumentTranslationIcon';
import PlanComponentPopup from 'pages/plans/PlanComponentPopup';
import UpgradeButton from 'pages/common/UpgradeButton';
import TrialUpgrade from 'pages/common/TrialUpgrade';
import MainSidebar from './MainSidebar';


const MobileDrawerOnlyLayout = () => {
  const [showPromoBanner, setShowPromoBanner] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const { state, dispatch } = useContext(StoreContext);
  const [userInfo, setUserInfo] = useState({});
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [openKeys, setOpenKeys] = useState(['voiceover']);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackSentInProgress, setFeedbackSentInProgress] = useState(false);

  const [feedbackMessage, setFeedbackMessage] = useState(null);

  const submitFeedbackMessageRef = useRef();

  const [propertyId, setPropertyId] = useState("648ee9e894cf5d49dc5e6825");
  const [widgetId, setWidgetId] = useState("1h3743de7");
  // const [userInfo, setUserInfo] = useState({});
  const [renderLiveChat, setRenderLiveChat] = useState(false);

  const [showPlanPopup, setShowPlanPopup] = useState(false);

  const navigate = useNavigate();

  const handleLogOut = () => {
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/logout/';
    let abortController = new AbortController();
    const signal = abortController.signal;
    let payload = {}

    UseAPI(signal, url, "GET", payload)
    .then(function(data) {
        // handle data here
        if(data.code === 200) {
          localStorage.clear();
          navigate('/login');
        }
        else {
            message.error("Error connecting to server");
        }
    })
    .catch(function(err) {
        if (err.name === 'AbortError') {
            console.log('Request aborted');
        } else {
            //alert("Error");
            message.error("Error connecting to server");
        }
    });
  };

  const handleDrawerOpen = () => {
    setMobileDrawerVisible(true);
  };
  
  const handleDrawerClose = () => {
    setMobileDrawerVisible(false);
  };
  
  const handleMenuClick = (e) => {
    if (mobileDrawerVisible) {
      handleDrawerClose();
    }
    setOpenKeys([e.key]);
  };

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const toggleArrowIcon = (key) => {
    return openKeys.includes(key) ? <UpCircleOutlined style={{ fontSize: '20px' }} /> : <DownCircleOutlined style={{ fontSize: '20px' }} />;
  };

  const handleMenuItemClick = () => {
    setMobileMenuVisible(false);
  };

  const handleSubmitFeedbackForm = (event) => {
    event.preventDefault();
    if(!feedbackMessage) {
      submitFeedbackMessageRef.current.focus();
      return false;
    }
    setFeedbackSentInProgress(true);
    let url = api_config.BASE_BACKEND_ENDPOINT + `api/submit-feedback/`;
    let apiController = new AbortController();
    const signal = apiController.signal;
    UseAPI(signal, url, 'POST', {
      message: feedbackMessage
    })
      .then(function (result) {
          if(result.code === 200) {
            setFeedbackSent(true);
          }
          else {
            notification.error({
              message: 'Unsuccessful',
              description: 'Your feedback could not be sent. Please try again later.',
            });
          }
          setFeedbackSentInProgress(false);
      })
      .catch(function (err) {
        setFeedbackSentInProgress(false);
        notification.error({
          message: 'Unsuccessful',
          description: 'Your feedback could not be sent. Please try again later.',
        });
      });
  };

  useEffect(() => {
    //alert(state.editor_expanded);
    //setCollapsed(state.editor_expanded);

    let url = api_config.BASE_BACKEND_ENDPOINT + `api/my-profile/`;
    let apiController = new AbortController();
    const signal = apiController.signal;
    UseAPI(signal, url, 'GET', {})
      .then(function (result) {
          if(result.code === 200) {
              dispatch({ type: 'UPDATE_STATE', payload: { show_chat_widget: true } });
              setUserInfo(result.user_info);
              setRenderLiveChat(true);
              if (window.Tawk_API) {
                clearInterval(checkTawkTo);
                window.Tawk_API.showWidget();
                window.Tawk_API.setAttributes({
                  'email': result.user_info.email,
                  'unique_id': result.user_info.unique_id,
                  'name': result.user_info.full_name,
                }, function(error){});
              }
          }
      })
      .catch(function (err) {
        
      });
    return () => {
      //document.body.style.overflow = 'auto';
      dispatch({ type: 'UPDATE_STATE', payload: { show_chat_widget: false } });
    };
  }, []);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onDrawerClose = () => {
    setMobileDrawerVisible(false);
  };

  const onDrawerOpen = () => {
    setMobileDrawerVisible(true);
  };

  useEffect(() => {
    //console.log("Path " + window.location.pathname);
    // Call API to create transcription
    let url = api_config.BASE_BACKEND_ENDPOINT + 'api/subscription-status/';
    api_config.ABORT_CONTROLLER = new AbortController();
    const signal = api_config.ABORT_CONTROLLER.signal;
    let payload = {};

    UseAPI(signal, url, 'POST', payload)
      .then(function (data) {
        // handle data here
        console.log(data);
        if (data.code === 200) {
          setSubscriptionInfo(data);
        } else if (data.code === 401) {
          localStorage.removeItem('user-token');
          window.location.href = '/login';
        } else {
          message.error('Error connecting to server');
        }
      })
      .catch(function (err) {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          message.error('Error connecting to server');
        }
      });

    // Abort fetch when component unmounts
    return () => {};
  }, []);
  

  const mobileDrawerMenu = (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[
        aboutSubRoutes.includes(window.location.pathname)
          ? '1'
          : rootRoutes.indexOf(window.location.pathname).toString(),
      ]}
      onClick={handleMenuItemClick}
    >
   
   <Menu.Item key="dash_mobile" onClick={handleMenuClick}>
        <Link to="/dashboard">
          <BarChartOutlined />
          <span className="menu-item-link">Dashboard</span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="voiceover_mobile"
        title={
          <span>
            <SoundOutlined />
            <span className="menu-item-link">TexttoSpeech</span>
          </span>
        }
      >
        <Menu.Item key="ai_voiceover_mobile" onClick={handleMenuClick}>
          <Link to="/studio">
            <Activity style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">Long Voice Studio(v2)</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_dialogue_studio_mobile" onClick={handleMenuClick}>
          <Link to="/dialogue-studio">
            <Activity style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">Dialogue Studio(v1)</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_voice-cloning_mobile" onClick={handleMenuClick}>
          <Link to="/voice-cloning">
            <Mic style={{ width: '18px', height: '18px', marginRight: '3px' }} />
            <span className="menu-item-link">Voice Cloning</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_audio_merge_requests_mobile" onClick={handleMenuClick}>
          <Link to="/my-audios">
            <List style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">Audio Files</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_my_drafts_mobile" onClick={handleMenuClick}>
          <Link to="/my-drafts">
             <ClockCircleOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">My Drafts</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_voices_mobile" onClick={handleMenuClick}>
          <Link to="/voicelist">
            <List style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">AI Voices</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="ai-audio-scrit-ai_mobile" onClick={handleMenuClick}>
          <Link to="/ai-audio-script">
            <span className="menu-item-link"><ScriptIcon /> AI ScriptBot</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="tools-subtitle-creator-mobile" onClick={handleMenuClick}>
          <Link to="/create-subtitle">
            <DownloadOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">Create Subtitle</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="tools-audio-converter-mobile" onClick={handleMenuClick}>
          <Link to="/tools/audio-converter/">
            <ToolOutlined style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">Audio Converter</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai-studio-widget-settings-mobile" onClick={handleMenuClick}>
          <Link to="/studio/widget-settings/">
            <span className="menu-item-link"><SettingOutlined />Widget Settings</span>
          </Link>
        </Menu.Item>
        {
          false && 
          <Menu.Item key="ai_vo_user_guide" onClick={handleMenuClick}>
            <Link to="/voiceover/user-guide">
              <HelpCircle style={{width: '18px', height: '18px', marginRight: '3px'}}/>
              <span className="menu-item-link">User Guide</span>
            </Link>
          </Menu.Item>
        }
      </SubMenu>
      <Menu.Item key="12" onClick={handleMenuClick}>
          <Link to="/chat-with-ai">
            <ChatbotIcon/>
            <span style={{paddingLeft: '3px'}} className="menu-item-link">Chat with AI</span>
          </Link>
        </Menu.Item>
      <SubMenu
        key="dubbing_mobile"
        title={
          <span>
            <BarChart2 size={'24px'}/>
            <span className="menu-item-link">Transcription</span>
          </span>
        }
      >
        <Menu.Item key="18" onClick={handleMenuClick}>
          <Link to="/transcriptions">
            <BarChart2 style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">My Transcriptions</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="ai_document-translator-mobile">
        <Link to="/document-translation">
          <span style={{marginRight: '3px'}}><DocumentTranslationIcon size='1rem' /></span> 
          <span className="menu-item-link">Doc Translation</span>
        </Link>
      </Menu.Item>
      <SubMenu
        key="ai_chat_copywriting_mobile"
        title={
          <span>
            <MessageOutlined />
            <span className="menu-item-link">Copywriting Tools</span>
          </span>
        }
      >
        {/* <Menu.Item key="ai_transcriptor_user_guide_mobile">
          <Link to="/dubbing-studio/user-guide">
            <HelpCircle style={{width: '18px', height: '18px', marginRight: '3px'}}/>
            <span className="menu-item-link">User Guide</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="23" onClick={handleMenuClick}>
          <Link to="/templates">
            <AppstoreOutlined />
            <span className="menu-item-link">Templates</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="17" onClick={handleMenuClick}>
          <Link to="/ai-image">
            <FileImageOutlined />
            <span className="menu-item-link">AI Image Studio <Badge count={'updated'} style={{ backgroundColor: '#52c41a' }}></Badge></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_transcriptor-temp-mobile" onClick={handleMenuClick}>
          <Link to="/templates/translator">
            <Globe style={{width: '21px', height: '21px', marginRight: '5px'}}/>
            <span className="menu-item-link">Translator</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="mn_writing_editor_mobile" onClick={handleMenuClick}>
          <Link to="/documents/new">
            <DocumentIcon />
            <span className="menu-item-link"><span style={{marginLeft: '10px'}}>AI Writing Editor</span></span>
          </Link>
        </Menu.Item>
        <Menu.Item key="ai_transformer-mobile" onClick={handleMenuClick}>
          <Link to="/templates/transformer">
            <TransformerIcon />
            <span className="menu-item-link" style={{marginLeft: '15px'}}>Transform Text</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="mn_my_notes_mobile" onClick={handleMenuClick}>
          <Link to="/documents">
            <FileDoneOutlined />
            <span className="menu-item-link">My Documents</span>
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="my_plan_key_mobile" onClick={handleMenuClick}>
        <Link to="/plan">
          <DollarCircleOutlined />
          <span className="menu-item-link">Pricing and Plans</span>
        </Link>
      </Menu.Item>
      {
        false && 
        <SubMenu
          key="history_mobile"
          title={
            <span>
              <HistoryOutlined />
              <span className="menu-item-link">History</span>
            </span>
          }
        >
          <Menu.Item key="history_chat_mobile" onClick={handleMenuClick}>
            <Link to="/chat-history">
              <HistoryOutlined />
              <span className="menu-item-link">Chat History</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="word_usage_history_mobile" onClick={handleMenuClick}>
            <Link to="/word-usage-history">
              <HistoryOutlined />
              <span className="menu-item-link">Word History</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      }
      <Menu.Item key="help_support_key_mobile" onClick={handleMenuClick}>
        <Link to="/help">
          <QuestionCircleOutlined />
          <span className="menu-item-link">Help & Support</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="settings_key_mobile" onClick={handleMenuClick}>
        <Link to="/settings">
          <SettingOutlined />
          <span className="menu-item-link">Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="features_roadmap_key_mobile">
        <Link to="/features-roadmap">
          <ProjectOutlined />
          <span className="menu-item-link">Product Roadmap</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="logout_key_mobile">
        <Link onClick={() => handleLogOut()}>
          <LogoutOutlined />
          <span className="menu-item-link">Logout</span>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout
          className={`content-with-margin`}
        >
          <div className="mobile-drawer-button" onClick={onDrawerOpen}>
            <MenuOutlined />
          </div>
          <Drawer
            title="Menu"
            placement="left"
            closable={true}
            onClose={onDrawerClose}
            open={mobileDrawerVisible}
            className="mobile-drawer"
          >
            {mobileDrawerMenu}
          </Drawer>
          <Outlet />
        </Layout>
      <TrialUpgrade />
    </>
  );
};

export default MobileDrawerOnlyLayout;